<template>
  <div class="box">
    <div class="headline">专题学习</div>
    <div class="content">
      <img src="../../assets/specificationImgs/study1.png" width="100%" />
      <p>点击指“派按”键进入指派任务列表</p>
      <p>进入指派界面后即可看见《专题学习》</p>
      <img src="../../assets/specificationImgs/study2.png" width="100%" />
      <br />
      <br />
      <p>分为两部分</p>
      <p>我的任务和我的发布</p>
      <p>我的任务为他人指派给我</p>
      <p>我的发布为我指派给他人</p>
      <p>点击未完成或者已完成进行筛选</p>
      <img src="../../assets/specificationImgs/study3.png" width="100%" />
      <br />
      <br />
      <br />
      <br />
      <p>通过任务完成时间筛选</p>
      <p>选择时间后 可以筛选出相对于的数据</p>
      <img src="../../assets/specificationImgs/study4.png" width="100%" />
      <p>
        需要注意每个任务都有最后期限，到期后，业务测评任务自动提交，学习任务自动结束，显示未完成。
      </p>
      <br />
      <br />
      <p>去业务测评和去学习</p>
      <img src="../../assets/specificationImgs/study5.png" width="100%" />
      <br />
      <br />
      <br />
      <p>点击去业务测评</p>
      <img src="../../assets/specificationImgs/study6.png" width="100%" />
      <br />
      <br />
      <br />
      <p>考完试提交后</p>
      <p>即不可重复业务测评</p>
      <img src="../../assets/specificationImgs/study7.png" width="100%" />
      <br />
      <br />
      <p>去学习</p>
      <p>点击去学习按钮</p>
      <img src="../../assets/specificationImgs/study8.png" width="100%" />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>